<template>
  <div class="container">
    <bread-crumb></bread-crumb>
    <router-view />
  </div>
</template>

<script>
import BreadCrumb from "@/components/UI/BreadCrumb";

export default {
  components: { BreadCrumb },
};
</script>
